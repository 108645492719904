.events{
    background-image: linear-gradient(to right,rgb(241, 241, 239), rgb(225, 0, 255) );
}

.londrina-sketch-regular {
    font-family: "Londrina Sketch", sans-serif;
    font-weight: bolder;
    font-style: normal;
    font-size: 3rem;
  }


 
/* .dosis-custom {
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
}
   */