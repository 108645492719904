.carousel{

    /* margin-top: -15%; */
    
    border-radius: 5%;
    padding: 3%;

}

.carousel img{

    width: 50%;
        
}