.pricing1 {
    background-color: white;
    transition: transform 0.6s ease, background-image 1.9s ease;
    box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.5); /* Change the values as needed */

     
}




.pricing1:hover {
    transform: scale(1.07);
    background-image: linear-gradient(to right, rgb(235, 207, 81), rgb(171, 120, 228));
}

.pricing2 {
    transition: transform 0.6s ease, background-image 1.9s ease;
    box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.5);
}

.pricing2:hover {
    transform: scale(1.07);
    background-image: linear-gradient(to right, rgb(255, 0, 221), rgb(171, 120, 228));

}

.pricing3 {
    transition: transform 0.6s ease, background-image 1.9s ease;
    box-shadow: 4px 6px 10px rgba(7, 4, 0, 0.5);
}

.pricing3:hover {
    transform: scale(1.07);
    background-image: linear-gradient(to right, rgb(255, 136, 0), rgb(171, 120, 228));

}
.pricing4 {
    transition: transform 0.6s ease, background-image 1.9s ease;
    box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.5);
}

.pricing4:hover {
    transform: scale(1.07);
    background-image: linear-gradient(to right, rgb(255, 17, 0), rgb(171, 120, 228));

}

.special1{
    transition: transform 0.6s ease, background-image 1.9s ease;
    box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.5);   
}

.special1:hover {
    transform: scale(1.07);
    background-image: linear-gradient(to right, rgb(217, 255, 0), rgb(171, 120, 228));

}
.special2{
    transition: transform 0.6s ease, background-image 1.9s ease;
    box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.5);   
}

.special2:hover {
    transform: scale(1.07);
    background-image: linear-gradient(to right, rgb(6, 238, 238), rgb(171, 120, 228));

}



button {
    /* border: 2pt solid black; */
    width: 60%;
    
 
}

button p {
    color: red;
    
}

.londrina-sketch-regular {
    font-family: "Londrina Sketch", sans-serif;
    font-weight: bolder;
    font-style: normal;
    font-size: 5rem;
  }