.Servecard {
    /* Add your ServeCard styles here */
    transition: transform 0.3s ease; /* Smooth transition effect */
    
}
  
  .Servecard:hover {
    transform: scale(1.1); /* Enlarge the card on hover */
  }
  
  .bubblegum-sans-regular {
    font-family: "Bubblegum Sans", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  