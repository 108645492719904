.schoolbell-regular {
    font-family: "Schoolbell", cursive;
    font-weight: 900;
    font-style: normal;
    font-size: 4rem;
  }
  

.franchise {

    padding: 5%;
    background-image: linear-gradient(to right, rgb(184, 95, 95), rgb(243, 243, 242));
    animation: slideInFromLeft 2s linear backwards;
}

@keyframes slideInFromLeft {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  

/* .bubblegum-sans-regular {
    font-family: "Bubblegum Sans", sans-serif;
    font-weight: 400;
    font-style: normal;

  } */

  .franchiseText{

    font-weight: 700;
    font-family: cursive;

    font-size: large;
    
    transition: transform 1s ease;
  }

  .franchiseText:hover{
    transform: scale(1.2); /* Enlarge the link on hover */
    background-image: linear-gradient(to left, rgb(198, 101, 228), rgb(114, 140, 197));
    /* color: rgb(48, 104, 187); */
  }

  