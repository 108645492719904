.vids{
    margin-left: 3%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 18px;
    border: 3px solid red;  
}

.player-container{
    /* display: flex;
    flex-direction: row;
  justify-content: center;
  align-items: center; */
  height: 20%;
  /* border: 3px solid red; */
}

.player-container{
    
        transition: transform 0.6s ease;
        margin-top: 4%;
      
      
    
}

.player-container:hover {
    transform: scale(1.20);
}
    /* box-shadow: 3px 3px 4px 4px rgb(117, 117, 185); */
  /* background-color: rgb(207, 30, 30);} */