.block{
    /* 
        border: 3pt solid red; */
        padding: 2%;
    }
    
    .content{
        margin-top: -17%;
    }
    
    .Icontainer{
        padding: 5%;
        margin-top: -23%;
    }
    .Icard{
        transition: transform 0.6s ease,
         /* Added ease for background-color */
    
    }
    
    .Icard:hover {
        transform: scale(1.06);
        
      }
      

 